<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet, IonMenuButton } from '@ionic/vue';
import { home, bookmark, chatbubbleEllipses } from 'ionicons/icons';
import { ref, inject } from 'vue'
import { useAuthStore } from '@/stores/auth.store'
const authStore = ref(false)
authStore.value = useAuthStore()
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const popovermanager = inject('popovermanager')
function onTabChange(e) {
  console.log('Tab changed', e)
  popovermanager.closeAll()
}
// this is the final step to the dashboard tour
const tour = inject('initialTour')
const tourStep3Text = t('tour.you-can-access-all-of-our-features-here')
console.log('tour', tour)
</script>

<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="onTabChange">
      <ion-router-outlet id="main-content" swipe-gesture="false"></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/dashboard">
          <ion-icon aria-hidden="true" :icon="home" />
            <ion-label>{{ t('navbar.dashboard') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/cards">
          <ion-icon aria-hidden="true" :icon="bookmark" />
            <ion-label>{{ t('navbar.flashcards') }}</ion-label>
        </ion-tab-button>
        
        <ion-tab-button v-if="authStore.details && authStore.details.course_available" tab="tab_study" href="/course">
          <ion-icon aria-hidden="true" :icon="bookmark" />
            <ion-label>{{ t('dashboard.study') }}</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab3" href="/chat">
          <ion-icon aria-hidden="true" :icon="chatbubbleEllipses" />
            <ion-label>{{ t('navbar.chat-mode') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4"
					v-tour-step:3="{
					tour: initialTour,
					options: {
						attachTo: { on: 'top' },
						text: tourStep3Text,
						buttons: [
						{
              classes: 'btn btn-primary btn-primary-cs btn-primary-sm',
							text: 'Done',
							action: initialTour.next
						},
						],
					}
          }
					"
        >
          <ion-menu-button></ion-menu-button>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

